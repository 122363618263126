import config from "gatsby-plugin-config"

export const sendEmail = async data => {
  const emailResult = await fetch("https://app.trypricelist.com/api/mail", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })

  return emailResult.status
}
