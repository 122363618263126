import React from "react"
import CookieConsent, {
  Cookies,
  getCookieConsentValue,
} from "react-cookie-consent"

export const CookieConsentContainer = () => {
  return (
    <div className="static bottom-0 left-0 w-full md:flex md:justify-end">
      <CookieConsent
        buttonText="Accept"
        cookieName="gatsby-gdpr"
        buttonClasses="rounded-full px-3 py-2 bg-indigo-700 text-indigo-50 w-auto"
        containerClasses="fixed bottom-8 right-0 m-8 md:w-1/5 text-xs bg-white rounded-md shadow-md p-4 text-gray-700 border"
        declineButtonClasses="text-indigo-700"
        buttonWrapperClasses="flex items-center space-x-4 justify-end"
        expires={150}
        disableStyles={true}
        acceptOnScroll={true}
        acceptOnScrollPercentage={50}
        enableDeclineButton
      >
        <CookieContent />
      </CookieConsent>
    </div>
  )
}

const CookieContent = () => (
  <>
    <p className="pb-4 text-sm font-semibold">Cookie Consent</p>
    <p className="pb-2 leading-loose">
      By clicking <span className="font-semibold">“Accept”</span>, you agree to
      the storing of cookies on your device to enhance site navigation, analyze
      site usage, and assist in our marketing efforts.
    </p>
  </>
)
