import React, { useState } from "react"
import { sendEmail } from "../utils/email"
import { useForm } from "react-hook-form"
import { Link } from "gatsby"
import solutions from "../data/solutions"
import slug from "slug"

const Footer = () => {
  const { register, handleSubmit } = useForm()
  const [submitted, setSubmitted] = useState(false)

  const onSubmit = async data => {
    const emailStatus = await sendEmail(data)

    setSubmitted(emailStatus === 200)
  }

  const footerLinks = [
    {
      section: "Solutions",
      links: solutions.map(({ name }) => ({
        name,
        to: `/solutions/${slug(name)}`,
      })),
    },
    // {
    //   section: "Company",
    //   links: [{ name: "About", to: "/company/about" }],
    // },
    // // {
    // //   section: "Legal",
    // //   links: [],
    // // },
    // // {
    // //   section: "Legal",
    // //   links: [],
    // // },
  ]

  return (
    // <></>
    <footer className="bg-white" aria-labelledby="footerHeading">
      <h2 id="footerHeading" className="sr-only">
        Footer
      </h2>
      {/* <ul className="flex items-center justify-center mt-16 h-12 text-gray-700 tracking-wide border-b border-t">
        <li className="mx-8">Security</li>
        <li className="mx-8">On-demand demo</li>
        <li className="mx-8">99.9% Uptime</li>
      </ul> */}
      <div className="mx-auto px-4 py-12 max-w-7xl sm:px-6 lg:px-8 lg:py-16">
        <div className="flex flex-col lg:flex-row">
          <div className="flex flex-col gap-x-16 w-full space-y-2 sm:flex-row sm:w-2/3 sm:space-y-0">
            {footerLinks.map(({ section, links }) => (
              <div>
                <h3 className="text-gray-400 text-sm font-semibold tracking-wider uppercase">
                  {section}
                </h3>
                <ul className="mt-2 space-y-2 sm:mt-4 md:space-y-4">
                  {links.map(({ name, to }) => (
                    <li>
                      <Link
                        to={to}
                        className="text-gray-500 hover:text-gray-900 text-base"
                      >
                        {name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <div className="w-full sm:w-1/3">
            <div className="mt-8 xl:mt-0">
              <h3 className="text-gray-400 text-sm font-semibold tracking-wider uppercase">
                Subscribe to our newsletter
              </h3>
              <p className="mt-4 text-gray-500 text-base">
                Subscripe to get the latest updates from Pricelist in your
                email.
              </p>
              <form
                className="mt-4 sm:flex sm:max-w-md"
                onSubmit={handleSubmit(onSubmit)}
              >
                <label htmlFor="emailAddress" className="sr-only">
                  Email address
                </label>
                <input
                  type="email"
                  name="emailAddress"
                  id="emailAddress"
                  autoComplete="email"
                  required
                  className="placeholder-gray-500 focus:placeholder-gray-400 px-4 py-2 w-full min-w-0 text-gray-900 text-base bg-white border border-gray-300 focus:border-indigo-500 rounded-md focus:outline-none shadow-sm appearance-none focus:ring-indigo-500"
                  placeholder="Enter your email"
                  ref={register}
                />
                <div className="mt-3 rounded-md sm:flex-shrink-0 sm:ml-3 sm:mt-0">
                  <button
                    type="submit"
                    className="flex items-center justify-center px-4 py-2 w-full text-white text-base font-medium bg-indigo-600 hover:bg-indigo-700 border border-transparent rounded-md focus:ring-indigo-500 focus:ring-offset-2 focus:ring-2"
                  >
                    Subscribe
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="xl:grid xl:gap-8 xl:grid-cols-3">
          <div className="space-y-8 sm:space-y-0 md:grid md:gap-8 md:grid-cols-4">
            {/* <div className="mt-12 md:mt-0">
                <h3 className="text-gray-400 text-sm font-semibold tracking-wider uppercase">
                  Support
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link
                      to="#"
                      className="text-gray-500 hover:text-gray-900 text-base"
                    >
                      Pricing
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="#"
                      className="text-gray-500 hover:text-gray-900 text-base"
                    >
                      Documentation
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="#"
                      className="text-gray-500 hover:text-gray-900 text-base"
                    >
                      Guides
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="#"
                      className="text-gray-500 hover:text-gray-900 text-base"
                    >
                      API Status
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="md:grid md:gap-8 md:grid-cols-2">
              <div>
                <h3 className="text-gray-400 text-sm font-semibold tracking-wider uppercase">
                  Company
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link
                      to="#"
                      className="text-gray-500 hover:text-gray-900 text-base"
                    >
                      About
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="#"
                      className="text-gray-500 hover:text-gray-900 text-base"
                    >
                      Blog
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="#"
                      className="text-gray-500 hover:text-gray-900 text-base"
                    >
                      Jobs
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="#"
                      className="text-gray-500 hover:text-gray-900 text-base"
                    >
                      Press
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="#"
                      className="text-gray-500 hover:text-gray-900 text-base"
                    >
                      Partners
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-gray-400 text-sm font-semibold tracking-wider uppercase">
                  Legal
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link
                      to="#"
                      className="text-gray-500 hover:text-gray-900 text-base"
                    >
                      Privacy
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="#"
                      className="text-gray-500 hover:text-gray-900 text-base"
                    >
                      Terms
                    </Link>
                  </li>
                </ul>
              </div> */}
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-200 md:flex md:items-center md:justify-between">
          <div className="flex space-x-6 md:order-2">
            {/* <Link to="#" className="text-gray-400 hover:text-gray-500">
              <span className="sr-only">Facebook</span>
              <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  clip-rule="evenodd"
                />
              </svg>
            </Link> */}

            <a
              href="https://twitter.com/trypricelist"
              className="text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">Twitter</span>
              <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
              </svg>
            </a>

            {/* <Link to="#" className="text-gray-400 hover:text-gray-500">
              <span className="sr-only">GitHub</span>
              <svg
                className="h-6 w-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                  clip-rule="evenodd"
                />
              </svg>
            </Link> */}
          </div>
          <p className="mt-8 text-gray-400 text-base md:order-1 md:mt-0">
            &copy; 2021 Pricelist, Inc. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
