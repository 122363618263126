import "@fontsource/lato/300.css"
import "@fontsource/lato/400.css"
import "@fontsource/lato/700.css"
import "@fontsource/lato/900.css"
import { Link } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import Rollbar from "rollbar"
import { CookieConsentContainer } from "./CookieConsentContainer"
import Footer from "./Footer"
import Navbar from "./Navbar"

new Rollbar({
  accessToken: process.env.REACT_APP_ROLLBAR,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: "static",
})

const Layout = ({ children, title, keywords, description, url }) => {
  const defaultDescription =
    "Pricelist streamlines go-to-market processes for teams and helps set optimal prices throughout product life cycle"

  const defaultKeywords =
    "pricing, prices, go-to-market, gtm, optimization, promotions, bundles, consomer electronics, analytics, collaboration"

  const defaultTitle =
    "Pricelist - An analytical and collaborative solution for pricing"

  const defaultURL = "https://trypricelist.com"

  return (
    <>
      <Helmet>
        <title>{title || defaultTitle}</title>
        <meta name="keywords" content={keywords || defaultKeywords} />
        <meta name="description" content={description || defaultDescription} />
        <meta
          prefix="og: https://trypricelist.com"
          property="og:title"
          content={title || defaultTitle}
        />
        <meta
          prefix="og: https://trypricelist.com"
          property="og:type"
          content="website"
        />
        <meta
          prefix="og: https://trypricelist.com"
          property="og:image"
          content={`https://res.cloudinary.com/trypricelist/image/fetch/v1631039344/https://www.trypricelist.com/static/skeletonHomepage-91474f1b587330e5802fbc5b67315f35.png`}
        />
        <meta
          prefix="og: https://trypricelist.com"
          property="og:url"
          content={url || defaultURL}
        />

        <link rel="stylesheet" type="text/css" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className="py-2 text-center text-indigo-800 text-sm bg-indigo-100 border-b border-indigo-600 shadow-xl">
        🚨 Don't miss Black Friday and Cyber Monday pricing strategy insights.{" "}
        <Link to={"/bfcm"} className="hover:no-underline underline font-bold">
          {" "}
          Get your free report now
        </Link>{" "}
        ⬇️
      </div>
      <Navbar />
      <main className="px-1 sm:px-0">{children}</main>
      <Footer />
      <CookieConsentContainer />
    </>
  )
}

export default Layout
