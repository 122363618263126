import { Transition } from "@headlessui/react"
import { Link } from "gatsby"
import {
  ChevronDown,
  CurrencyDollarOutline,
  LightBulbOutline,
  MenuOutline,
  OfficeBuildingOutline,
  QuestionMarkCircleOutline,
  UserGroupOutline,
  ViewBoardsOutline,
  ViewGridOutline,
  XOutline,
} from "heroicons-react"
import React, { useState } from "react"
import tw from "twin.macro"
import logo from "../assets/favicon.png"
const slug = require("slug")

const Group = tw.button`
    bg-white rounded-md text-gray-500 inline-flex items-center text-base font-medium hover:text-gray-900 duration-300 transform focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`
Group.Child1 = tw.svg`ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500`

const MenuItem = ({ header, to }) => (
  <Link
    to={to}
    className="text-gray-500 hover:text-gray-900 text-base font-medium"
  >
    {header}
  </Link>
)

const SubMenuItem = ({ icon, header, description, to }) => {
  return (
    <Link
      to={to}
      className="flex items-start -m-3 p-3 hover:text-indigo-700 hover:bg-gray-100 rounded-lg hover:cursor-pointer transition duration-300 ease-in-out"
    >
      <div className="flex flex-shrink-0 items-center justify-center w-10 h-10 text-white bg-indigo-500 rounded-md sm:w-12 sm:h-12">
        <svg
          className="w-6 h-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          {icon}
        </svg>
      </div>
      <div className="ml-4">
        <p className="text-gray-900 hover:text-indigo-700 text-base font-medium">
          {header}
        </p>
        <p className="mt-1 text-gray-500 text-sm">{description}</p>
      </div>
    </Link>
  )
}

const SubMenuItemCompact = ({ icon, header, to }) => {
  return (
    <Link
      to={to}
      className="flex items-center -m-3 p-2 text-xs hover:bg-gray-50 rounded-lg"
    >
      <div className="ml-4 text-gray-900 text-sm">{header}</div>
    </Link>
  )
}

const MenuBottom = ({ header, description, to }) => {
  return (
    <div className="p-5 bg-gray-100 sm:p-8">
      <Link to={to} className="flow-root -m-3 p-3 hover:bg-gray-100 rounded-md">
        <div className="flex items-center">
          <div className="text-gray-900 text-base font-medium">{header}</div>
          <span className="inline-flex items-center ml-3 px-3 py-0.5 text-indigo-800 text-xs font-medium leading-5 bg-indigo-100 rounded-full">
            New
          </span>
        </div>
        <p className="mt-1 text-gray-500 text-sm">{description}</p>
      </Link>
    </div>
  )
}

const MobileSimpleLink = tw(Link)`
  text-base font-medium text-gray-900 hover:text-gray-700
`

const solutionMenuItems = [
  {
    icon: <LightBulbOutline />,
    header: "Intelligent Pricing Automation",
    description: "Cut your pricing process from weeks to minutes.",
    to: "/solutions/" + slug("Intelligent Pricing Automation"),
  },

  {
    icon: <UserGroupOutline />,
    header: "Go-to-Market Collaboration",
    description: "Break down silos to keep everyone in the loop.",
    to: "/solutions/" + slug("Go-to-Market Collaboration"),
  },
  {
    icon: <ViewGridOutline />,
    header: "Competitive Intelligence",
    description:
      "Understand your competitor's pricing dynamics on a daily level.",
    to: "/solutions/" + slug("Competitive Intelligence"),
  },
  {
    icon: <CurrencyDollarOutline />,
    header: "Promotion Planning",
    description: "Plan your promotions in a fast and consistent way.",
    to: "/solutions/" + slug("Promotion Planning"),
  },

  {
    icon: <ViewBoardsOutline />,
    header: "Bundle Planning",
    description: "Determine the exposure and impact of bundle offers.",
    to: "/solutions/" + slug("Bundle Planning"),
  },

  // {
  //   icon: <DocumentReportOutline />,
  //   header: "Reporting",
  //   description:
  //     "Get detailed reports that will help you make more informed decisions.",
  //   to: "/solutions/" + slug("Reporting"),
  // },
]

const productMenuItems = [
  {
    icon: <QuestionMarkCircleOutline />,
    header: "Why Pricelist?",
    description: "Learn how Pricelist can deliver value to your company.",
    to: "/product/why-pricelist",
  },

  // {
  //   icon: <CollectionOutline />,
  //   header: "Features",
  //   description:
  //     "Explore Pricelist's key analytical and collaborative features.",
  //   to: "/product/features",
  // },

  {
    icon: <ViewBoardsOutline />,
    header: "Compare",
    description: "Compare Pricelist to other solutions.",
    to: "/product/compare",
  },

  {
    icon: <OfficeBuildingOutline />,
    header: "For Enterprise",
    description: "See how Pricelist integrates into your enterprise setting.",
    to: "/product/for-enterprise",
  },
]

const MenuButton = ({ isOpen, onClick }) => {
  let menuProperties = {
    title: "Open menu",
    icon: <MenuOutline />,
  }

  if (isOpen) {
    menuProperties = {
      title: "Close menu",
      icon: <XOutline />,
    }
  }

  return (
    <button
      type="button"
      className="inline-flex items-center justify-center p-2 text-gray-400 hover:text-gray-500 hover:bg-gray-100 bg-white rounded-md focus:outline-none focus:ring-indigo-500 focus:ring-2 focus:ring-inset"
      onClick={onClick}
    >
      <span className="sr-only">{menuProperties.title}</span>
      <svg
        className="w-6 h-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        {menuProperties.icon}
      </svg>
    </button>
  )
}

const Navbar = () => {
  const [hover, setHover] = useState(false)
  const [productHover, setProductHover] = useState(false)
  const [menuVisible, setMenuVisible] = useState([false, false])

  return (
    <>
      {/* <BannerAlert visible={true}>
        <p>
          Pricelist{" "}
          <span className="text-center w-4 inline-block rounded-full bg-purple-400 text-purple-900 tracking-normal">
            β
          </span>{" "}
          is launching soon!{" "}
          <Link
            className="underline hover:no-underline font-semibold"
            to={"/sign-up-for-beta"}
          >
            Sign up for an account
          </Link>
        </p>
      </BannerAlert> */}
      <header>
        <div className="relative bg-white">
          <div className="flex items-center justify-between px-4 py-6 sm:px-6 md:justify-start md:space-x-10">
            <div className="flex justify-start lg:flex-1 lg:w-0">
              <Link to="/">
                <span className="sr-only">Pricelist</span>
                <img className="w-auto h-10" src={logo} alt="" />
              </Link>
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              <MenuButton
                isOpen={false}
                onClick={() =>
                  setHover(prevState => {
                    return !prevState
                  })
                }
              />
            </div>
            <nav className="z-50 hidden space-x-10 md:flex">
              <div className="relative">
                {/* <!-- Item active: "text-gray-900", Item inactive: "text-gray-500" --> */}
                <Group
                  className="group"
                  onClick={() =>
                    setProductHover(prevState => {
                      if (hover) setHover(false)
                      return !prevState
                    })
                  }
                >
                  <span>Product</span>
                  {/* <!--
            Item active: "text-gray-600", Item inactive: "text-gray-400"
          --> */}
                  <Group.Child1
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <ChevronDown />
                  </Group.Child1>
                </Group>

                {/* <!--
          'Solutions' flyout menu, show/hide based on flyout menu state.

          Entering: "transition ease-out duration-200"
            From: "opacity-0 translate-y-1"
            To: "opacity-100 translate-y-0"
          Leaving: "transition ease-in duration-150"
            From: "opacity-100 translate-y-0"
            To: "opacity-0 translate-y-1"
        --> */}
                <Transition show={productHover} appear={true}>
                  <Transition.Child
                    show={productHover}
                    enter="transition ease-out duration-0"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-0"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <div className="absolute z-50 -ml-4 mt-3 w-screen max-w-md transform lg:left-1/2 lg:ml-0 lg:max-w-xl lg:-translate-x-1/2">
                      <div className="rounded-lg shadow-lg overflow-hidden ring-black ring-opacity-5 ring-1">
                        <div className="relative grid gap-6 px-5 py-6 bg-white sm:gap-8 sm:p-8 lg:grid-cols-1">
                          {productMenuItems.map(
                            ({ to, description, header, icon }, idx) => (
                              <SubMenuItem
                                key={idx}
                                to={to}
                                description={description}
                                header={header}
                                icon={icon}
                              />
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </Transition.Child>
                </Transition>
              </div>
              <div className="relative">
                {/* <!-- Item active: "text-gray-900", Item inactive: "text-gray-500" --> */}
                <Group
                  className="group"
                  onClick={() =>
                    setHover(prevState => {
                      if (productHover) setProductHover(false)
                      return !prevState
                    })
                  }
                >
                  <span>Solutions</span>
                  {/* <!--
            Item active: "text-gray-600", Item inactive: "text-gray-400"
          --> */}
                  <Group.Child1
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <ChevronDown />
                  </Group.Child1>
                </Group>

                {/* <!--
          'Solutions' flyout menu, show/hide based on flyout menu state.

          Entering: "transition ease-out duration-200"
            From: "opacity-0 translate-y-1"
            To: "opacity-100 translate-y-0"
          Leaving: "transition ease-in duration-150"
            From: "opacity-100 translate-y-0"
            To: "opacity-0 translate-y-1"
        --> */}
                <Transition show={hover} appear={true}>
                  <Transition.Child
                    show={hover}
                    enter="transition ease-out duration-0"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-0"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <div className="absolute z-50 -ml-4 mt-3 w-screen max-w-md transform lg:left-1/2 lg:ml-0 lg:max-w-2xl lg:-translate-x-1/2">
                      <div className="rounded-lg shadow-lg overflow-hidden ring-black ring-opacity-5 ring-1">
                        <div className="relative grid gap-6 px-5 py-6 bg-white sm:gap-8 sm:p-8 lg:grid-cols-2">
                          {solutionMenuItems.map(
                            ({ to, description, header, icon }, idx) => (
                              <SubMenuItem
                                key={idx}
                                to={to}
                                description={description}
                                header={header}
                                icon={icon}
                              />
                            )
                          )}
                        </div>
                        <MenuBottom
                          header="Enterprise"
                          description="Learn how to integrate Pricelist into your enterprise setup."
                          to="/product/for-enterprise"
                        />
                      </div>
                    </div>
                  </Transition.Child>
                </Transition>
              </div>
              <div className="relative">
                {/* <!-- Item active: "text-gray-900", Item inactive: "text-gray-500" --> */}

                <Link
                  to="/markets/"
                  className="text-gray-500 hover:text-gray-900 font-medium transform duration-300"
                >
                  Market Coverage
                </Link>
              </div>

              {/* <div className="relative">
                <Link
                  to="/pricing/"
                  className="text-gray-500 hover:text-gray-900 font-medium transform duration-300"
                >
                  Pricing
                </Link>
              </div> */}

              {/* <MenuItem to="/pricing" header="Pricing" />
              <MenuItem to="/docs" header="Docs" /> */}
              {/* <MenuItem to="/integrations" header="Integrations" /> */}
            </nav>
            <div className="hidden items-center justify-end space-x-5 md:flex md:flex-1 lg:w-0">
              {/* <MenuItem to="disabled" header="Sign In" /> */}
              <Link
                to="/contact-sales"
                className="inline-flex items-center justify-center ml-8 px-4 py-2 text-white hover:text-opacity-100 text-opacity-90 whitespace-nowrap text-base font-medium bg-indigo-600 hover:bg-indigo-700 border border-transparent rounded-md shadow-sm duration-300"
              >
                Get a Demo
              </Link>
            </div>
          </div>
          <Transition
            show={hover}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="absolute z-10 inset-x-0 top-0 p-2 transform origin-top-right transition md:hidden">
              <div className="bg-white rounded-lg shadow-lg divide-gray-50 divide-y-2 ring-black ring-opacity-5 ring-1">
                <div className="pb-6 pt-5 px-5">
                  <div className="flex items-center justify-between">
                    <div>
                      <img className="w-auto h-10" src={logo} alt="Pricelist" />
                    </div>
                    <div className="-mr-2">
                      <MenuButton
                        isOpen={true}
                        onClick={() =>
                          setHover(prevState => {
                            return !prevState
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="mt-6">
                    <nav className="grid gap-6 grid-cols-1">
                      <MobileSimpleLink
                        to="/product"
                        className="-mb-2 font-bold"
                      >
                        Product
                      </MobileSimpleLink>
                      {productMenuItems.map(({ to, header, icon }, idx) => (
                        <SubMenuItemCompact
                          to={to}
                          header={header}
                          icon={icon}
                          key={idx}
                        />
                      ))}
                    </nav>
                    <nav className="grid gap-6 grid-cols-1">
                      <MobileSimpleLink
                        to="/solutions"
                        className="-mb-2 mt-4 font-bold"
                      >
                        Solutions
                      </MobileSimpleLink>
                      {solutionMenuItems.map(({ to, header, icon }, idx) => (
                        <SubMenuItemCompact
                          to={to}
                          header={header}
                          icon={icon}
                          key={idx}
                        />
                      ))}
                    </nav>
                  </div>
                </div>
                <div className="px-5 py-6">
                  <div className="grid gap-4 grid-cols-2">
                    {/* <MobileSimpleLink to="/pricing">Pricing</MobileSimpleLink>
                    <MobileSimpleLink to="/docs">Docs</MobileSimpleLink> */}
                    {/* <MobileSimpleLink to="/integrations">
                      Integrations
                    </MobileSimpleLink> */}
                    <MobileSimpleLink
                      to="/contact-sales"
                      className="col-span-2 p-2 w-full text-white bg-indigo-600 focus:bg-indigo-800 rounded-md"
                    >
                      <span className="w-full hover:text-indigo-200 text-white">
                        Contact Sales
                      </span>
                    </MobileSimpleLink>
                  </div>
                  <div className="mt-6">
                    <p className="mt-6 text-gray-500 text-base font-medium">
                      Existing customer?{" "}
                      <a
                        href="https://app.trypricelist.com/"
                        className="hover:text-indigo-500 text-indigo-600"
                      >
                        Sign in
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </header>
    </>
  )
}

export default Navbar
