const solutions = [
  {
    name: "Intelligent Pricing Automation",
    description: "Cut your pricing process from weeks to minutes.",
    to: "/solutions/pricing-automation",
    content: {
      heading: "Optimal Pricing for Your Products",
      description:
        "Maintain and reference these prices during the entire life cycle in a single source of truth. Pricelist's guided process gives you the power to create, review, and approve optimal prices in minutes.",
      benefits: [
        "Transparent, arbitrage-free pricing process",
        "Up-to-date taxes, duties, and exchange rates",
        "Automated alerts for currency or tax changes",
        "Competitor product comparisons",
        "Price localization to meet customer expectations",
      ],
    },
  },

  {
    name: "Go-to-Market Collaboration",
    description: "Break down silos to keep everyone in the loop.",
    to: "/solutions/go-to-market-collaboration",
    content: {
      heading: "Break Team Silos. Work Collaboratively Instead",
      description:
        "Pricelist reshapes your go-to-market process by shifting your priorities from collection of inputs to discussions of results. Our technology reduces collaboration overhead, misunderstandings, and misalignments. Its friendly user experience improves team productivity and morale.",
      benefits: [
        "Integrated price approval tool",
        "Single source of truth for future and historical decisions",
        "Insights for go-to-market planning",
      ],
    },
  },
  {
    name: "Competitive Intelligence",
    description:
      "Understand your competitor's pricing dynamics on a daily level.",
    to: "/solutions/competitive-intelligence",
    content: {
      heading: "Understand Your Competitors Global Price Dynamics",
      description:
        "Pricelist allows you to track your competitors prices anywhere in the world every morning.",
      benefits: [
        "Analytics of promotion dynamics",
        "Automated alerts when prices change",
        "Review of global pricing strategies",
      ],
    },
  },
  {
    name: "Promotion Planning",
    description: "Plan your promotions in a fast and consistent way.",
    to: "/solutions/promotion-planning",
    content: {
      heading: "Smart Promotion Planning",
      description:
        "Pricelist provides an intuitive interface to plan your global promotions. Add your price elasticities to estimate the uplift over baseline sales.",
      benefits: [
        "Global promotional pricing in seconds",
        "Volume uplift simulations",
        "Decisions audit trail",
      ],
    },
  },

  {
    name: "Bundle Planning",
    description: "Determine the exposure and impact of bundle offers.",
    to: "/solutions/bundle-planning",
    content: {
      heading: "Comprehensive Bundle Planning",
      description:
        "Pricelist allows you to plan and understand the P&L impact of your bundle promotions. Bundle your products with other hardware and software in any market where you operate.",
      benefits: [
        "P&L impact monitoring",
        "Cost allocation into bundle components",
        "Source of truth for future promotions",
      ],
    },
  },

  // {
  //   name: "Reporting",
  //   description:
  //     "Get detailed reports that will help you make more informed decisions.",
  //   to: "/solutions/reporting",
  //   content: {
  //     heading: "Simple Ways to Export and Share Your Results",
  //     description: "",
  //     benefits: ["Full data export. You own your data", "", ""],
  //   },
  // },
]

module.exports = solutions
